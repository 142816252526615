const fetch = require('node-fetch');

import { useState, useEffect, useRef, useCallback } from 'react'

export default function useNearbyOffices(offices, maxRange=50) {
    const [nearbyOffices, setNearbyOffices] = useState([])
    const [userInput, setUserInput] = useState({})
    const [googleMaps, setGoogleMaps] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")


    useEffect(() => {
        if(googleMaps && userInput.lat) {
            distanceMatrix(userInput)
        }
    }, [googleMaps, userInput])

    const sortNearbyOffices = (offices, distancesResponse, maxDistance=50) => {  
        if (distancesResponse[0].status && distancesResponse[0].status === "ZERO_RESULTS") {
            setErrorMessage(`There were no locations found within ${maxDistance} miles`)
            return []
        }

        let withinRange = offices.map((office, i) => {
            if (distancesResponse[i].distance.value && (distancesResponse[i].distance.value * 0.000621) < maxDistance ) {
                return {
                    ...office,
                    distance: distancesResponse[i]?.distance?.text,
                    distanceInMeters: distancesResponse[i]?.distance?.value
                }
            }
            return undefined
        }).filter(obj => obj);

        if (withinRange.length > 0) {
            withinRange = withinRange.sort((a, b) => a.distanceInMeters - b.distanceInMeters)
        }
    
        return withinRange
    };

    const distanceMatrix = (origin) => {
        setErrorMessage("")
        var service = new window.google.maps.DistanceMatrixService();
        const dest = offices.map(item => (
            {lat: parseFloat(item.coords.lat), lng: parseFloat(item.coords.lng)}
        ))

        const request = {
            origins: [origin],
            destinations: dest,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
        };

        service.getDistanceMatrix(request).then((response) => {
            const sorted = sortNearbyOffices(offices, response.rows[0].elements)

            if (sorted.length === 0) {
                setErrorMessage(`There were no locations found within ${maxRange} miles`)
            }
            setNearbyOffices(sorted)
        });
    }

    return { 
        sortNearbyOffices,
        nearbyOffices, 
        setNearbyOffices,
        setGoogleMaps,
        setUserInput,
        errorMessage
    }
}

