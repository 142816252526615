import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from '../ImageMeta'
import { wrapSup } from '../../utils/utils'


const FeaturedProcedures = ({ heading, blurb, procedures, colorBack}) => {
    const mainClasses = classNames({
        "p-section": true,
        "color-back": colorBack,
        "featured-procedures": true
    })
    return (
        <div className={mainClasses}>
            <div className="columns">
                <div className="column is-3"></div>
                <div className="column">
                    <h3 className="has-text-centered">{heading}</h3>
                    {blurb &&
                        <MarkdownViewer markdown={blurb} />
                    }

                    <div className="featured-procedures__grid">
                        {procedures.map((item, i) => (
                            <div key={i} className="featured-procedures__card">
                                <Link to={`${item.url}`} title={`Learn more about ${item.name}`}>
                                    <ImageMeta
                                        cloudName="nuvolum"
                                        publicId={item.imageId}
                                        responsive
                                        width="auto"
                                    />
                                </Link>

                                <div>
                                    <Link className="hoverable" to={`${item.url}`} title={`Learn more about ${item.name}`}>
                                        <h5 dangerouslySetInnerHTML={{__html: wrapSup(item.name)}}></h5>
                                    </Link>
                                    <p>{item.blurb}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="column is-3"></div>
            </div>
        </div>
    )
}

export default FeaturedProcedures
