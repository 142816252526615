import React from 'react'
import Button from "@bit/azheng.joshua-tree.button";

const LocationCard = ({ name, city, address, phone, googleLink, milesAway, setSelectedOffice }) => {
    return (
        <div className="nearby-location-card">
            <div className="nearby-location-card__name">
                <span onClick={setSelectedOffice} style={{cursor: "pointer"}}>
                    <h5>{name}</h5>
                    <h5>{city}</h5>
                </span>
                <p>{`${milesAway}les away`}</p>
            </div>

            <div className="nearby-location-card__address">
                <p>{address.street}</p>
                <p>{`${address.city}, ${address.stateAbbr} ${address.zip}`}</p>
                <p>{phone}</p>
            </div>

            <div className="nearby-location-card__button">
                <Button
                    buttonText="Directions"
                    href={googleLink}
                    external
                />
            </div>
        </div>
    )
}

export default LocationCard
