import React from 'react'
import Button from '@bit/azheng.joshua-tree.button';
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const FirstTimePanels = (props) => {

    // const arr = [{title: "ready to serve you", heading: "same day appointments", blurb: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, accusamus.", button: "learn more"},
    //     {title: "ready to serve you", heading: "same day appointments", blurb: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, accusamus.", button: "learn more"},
    //     {title: "ready to serve you", heading: "same day appointments", blurb: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, accusamus.", button: "learn more"},
    // ]

    const arr = props.data;
    
    return (
        <div className="columns p-section color-back">
            <div className="column is-4"></div>
            <div className="column first-time-panels-container">
               {arr.map((data, i) => (
                    <div key={i} className="first-time-panel">
                        <h4>{data.subheading}</h4>
                        <MarkdownViewer markdown={data.heading} />
                        <MarkdownViewer markdown={data.text} />
                        <Button 
                            standalone
                            contained={data.button.appearance === "contained"}
                            minimal={data.button.appearance === "minimal"}
                            external={data.button.destination === "external"}
                            tel={data.button.destination === "tel"}
                            key={data.button.href} 
                            buttonText={data.button.buttonText} 
                            href={data.button.href}
                        />
                    </div>
                ))} 
            </div>
            <div className="column is-4"></div>
        </div>
    )
}

export default FirstTimePanels
