import React, { useState, useEffect } from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

import useNearbyOffices from './useNearbyOffices'
import LocationCard from './LocationCard';
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

import offices from '../../../allOffices.json'

const libraries = ["places"];

const mapContainerStyle = {
  height: "500px",
  width: "100%",

};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const center = {
  lat: 35.17383892245041,
  lng: -101.86590283485491
};


const OfficeLocator = () => {

    const [selectedOffice, setSelectedOffice] = useState(null);
    const [appError, setAppError] = useState(null)

    const { nearbyOffices, setGoogleMaps, setUserInput, errorMessage } = useNearbyOffices(offices)



    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
        libraries
    })

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
      mapRef.current = map;
      setGoogleMaps(map)
    }, []);



    useEffect(() => {
      if (mapRef && nearbyOffices.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();

        nearbyOffices.map(item => {
          bounds.extend({
            lat: parseFloat(item.coords.lat),
            lng: parseFloat(item.coords.lng),
          })
        })
        mapRef.current.fitBounds(bounds);
      }
    },[nearbyOffices, mapRef])

    return (

        <>
        {isLoaded && 
            <Search 
                setError={setAppError}
                setUserLocation={setUserInput} 
                nearbyOffices={nearbyOffices}
                errorMessage={errorMessage}
            /> 
        }


            <div 
                className={`office-search-results ${nearbyOffices.length > 0 ? "visible-map" : ""}`}
            >

                <div className="nearby-office-list">
                    {nearbyOffices.map((item, i) => (
                        <React.Fragment key={item.distance}>
                            <LocationCard 
                                name={item.name}
                                city={item.address.city}
                                address={item.address}
                                phone={item.phone}
                                milesAway={item.distance}
                                googleLink={item.gmb}
                                setSelectedOffice={() => setSelectedOffice(item)}
                            />
                        </React.Fragment>
                    ))}
                </div>


                {isLoaded &&
                    <div className="google-map">
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={center}
                            zoom={10}
                            options={options}
                            onLoad={onMapLoad}
                        >
                            <>
                                {nearbyOffices.map((marker) => (
                                <Marker
                                    key={marker.address.city}
                                    position={{ lat: parseFloat(marker.coords.lat), lng: parseFloat(marker.coords.lng) }}
                                    onClick={() => {
                                      setSelectedOffice(marker);
                                    }}
                                    
                                />
                                ))}

                                {selectedOffice && 
                                    <InfoWindow
                                        position={{lat: parseFloat(selectedOffice.coords.lat), lng: parseFloat(selectedOffice.coords.lng)}}
                                        onCloseClick={() => {
                                            setSelectedOffice(null);
                                          }}
                                    >
                                        <div className="location-info-window">
                                            <h5>{selectedOffice.address.city}</h5>
                                            <h6>Hours</h6>
                                            <MarkdownViewer markdown={selectedOffice.hours} />
                                            <p className="info-window-directions">
                                                <a href={selectedOffice.gmb} target="_blank">Directions</a>
                                            </p>
                                        </div>
                                    </InfoWindow>
                                }
                            </>
                        </GoogleMap>
                    </div>
                }
            </div>
        </>
    )
};

function Search({ setUserLocation, setAppError, errorMessage }) {
    const [submitted, setSubmitted] = useState(false)
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleInput = (e) => {
      setValue(e.target.value);
    };
  
    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            setUserLocation({lat, lng})
            setSubmitted(true)
        } catch (error) {
            setAppError(error);
        }
    };

    const onButtonSubmit = async () => {
        try {
            const results = await getGeocode({ address: value });
            const { lat, lng } = await getLatLng(results[0]);
            setUserLocation({lat, lng})
            setSubmitted(true)
        } catch (error) {
            setAppError(error);
        }
    }
  
    return (
        <div className="office-locator">
            <div className="office-search-bar">
                <h3>Find your nearest office</h3>

                <div className="office-search-form">
                    {data &&
                        <Combobox onSelect={handleSelect} aria-labelledby="demo">
                            <ComboboxInput 
                                value={value} 
                                onChange={handleInput} 
                                disabled={!ready} 
                                placeholder="City/Zip Code"
                            />
                            <ComboboxPopover style={{zIndex: 40}}>
                            <ComboboxList>
                                {status === "OK" &&
                                data.map(({ place_id, description }) => (
                                    <ComboboxOption key={place_id} value={description} />
                                ))}
                            </ComboboxList>
                            </ComboboxPopover>
                        </Combobox>
                    }

                    <button className="standard" onClick={() => onButtonSubmit()}>
                        Submit
                    </button>
                </div>
            </div>

            {errorMessage && submitted && 
                <p className="no-search-results">{errorMessage}</p>
            }
        </div>
    );
  }

export default OfficeLocator
